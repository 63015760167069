jQuery(document).ready(function () {

    const videoAttributes = jQuery('#dvVideoAttributes');
    const bannerInfo = jQuery('#bannerinfo')

    // If video-url exists and is not mobile
    if ((videoAttributes.attr('video-url') || bannerInfo.attr('video-url')) && window.innerWidth > 680) {
        var width;
        var height;
        var url;

        if (videoAttributes.attr('video-url')) {
            width = videoAttributes.attr('video-width');
            height = videoAttributes.attr('video-height');
            url = videoAttributes.attr('video-url');
        } else if (bannerInfo.attr('video-url')) {
            width = bannerInfo.attr('video-width');
            height = bannerInfo.attr('video-height');
            url = bannerInfo.attr('video-url');
        }

        // Add banner-video class to banner
        jQuery('#bannerbg').addClass('banner-video');

        // Add attributes to video
        const video = jQuery('#vdBannerVideo');
        video.attr('width', width);
        video.attr('height', height);
        video.attr('autoplay', '');

        // Add source to video
        const source = document.createElement('source');
        source.setAttribute('src', url);
        video.append(source);
    }

    jQuery('.js-field-load-assigntohidden').each(function () {
        var fieldValue = $(this).val().split(",");

        fieldValue.forEach(function (entry) {
            jQuery(".js-field-click-assigntohidden[data-value='" + entry + "']").addClass("active");
        });

    });

    jQuery('.js-field-click-assigntohidden').on('click', function (e) {

        var realFieldID = jQuery(this).attr("data-inputid");
        var matchingField = jQuery('#' + realFieldID);

        var currentValue = $(matchingField).val();

        var arrayOfValues = [];

        if (currentValue || currentValue.length !== 0) {
            arrayOfValues = $(matchingField).val().split(",");
        }

        var wasActive = jQuery(this).hasClass("active");

        jQuery(this).toggleClass("active");

        var value = jQuery(this).attr("data-value");

        if (wasActive) {
            var index = arrayOfValues.indexOf(value);
            if (index !== -1) arrayOfValues.splice(index, 1);
        }
        else {
            arrayOfValues.push(value);
        }

        if (arrayOfValues.length > 0) {
            $(matchingField).val(arrayOfValues.join(","));
        }
        else {
            $(matchingField).val(arrayOfValues[0]);
		}
    }); 

    // Menu    
    jQuery('.head').addClass('menu-closed');

    jQuery('.btn-menu').on('click', function(e){
        e.preventDefault();
        jQuery('.head').toggleClass('menu-closed');
    });

    jQuery('.btn-search a').on('click', function(e){
        e.preventDefault();
        jQuery('.head').toggleClass('search-open');
    });

    jQuery('.btn-recite a').each(function(){
        jQuery(this).on('click', function(e){
            e.preventDefault();
            loadService();
        });
    });

    // Sticky page nav
    jQuery(window).on('scroll', function(){
        var scrolled = jQuery(document).scrollTop();
        var navOffset = (jQuery('.head').height() + jQuery('.banner').height() + 100);
        if(scrolled > navOffset) {
            jQuery('.pn-stick').addClass('sticky');
        } else {
            jQuery('.pn-stick').removeClass('sticky');
        }
    });

    if(jQuery('.section-btm-box').length) {
        jQuery('.section-btm-box').onScreen({
            tolerance: 480,
             doIn: function() {
                    jQuery('.pn-stick').closest('.container').addClass('removeSticky');
               },
               doOut: function() {
                    jQuery('.pn-stick').closest('.container').removeClass('removeSticky');
               }
        });
    }

    // Page nav
    if(!jQuery('.page-nav .active').length) {
        jQuery('.page-nav:not(.period-nav) li').first().next().addClass('active');
    }
    jQuery('.page-nav .btn-sections a').on('click', function(e){
        e.preventDefault();
        jQuery(this).parents('.page-nav').toggleClass('opened');
    });



    // Fitvids
    if(jQuery().fitVids) {
        jQuery('.content').fitVids();
    };



    // masonry
    if(jQuery('#masonry').length) {
        var $container = jQuery('#masonry');
        // initialize
        if(jQuery().packery) {
            $container.packery({
                itemSelector: '.item'
            });
            $container.imagesLoaded(function() {
                $container.packery();
            });
        }
    }



    jQuery('.btn-scroll-projects').on('click', function(e){
        e.preventDefault();
        var hash = jQuery(this).attr('href');
        var scroll = (jQuery(hash).offset().top);
        jQuery('html, body').animate({scrollTop: scroll}, 500);
    });
    jQuery('.btn-scroll-map').on('click', function(e){
        e.preventDefault();
        var hash = jQuery(this).attr('href');
        var scroll = (jQuery(hash).offset().top);
        jQuery('html, body').animate({scrollTop: scroll}, 500);
    });



    // Remove empty links and paragraphs
    jQuery('a[href=""]').remove();
    jQuery('.caption p').filter(function () { return jQuery.trim(this.innerHTML) == "" }).remove();



    // Hack banner
    if(jQuery('#bannerinfo').length) {
        var title = jQuery('#bannerinfo').data('title');
        var description = jQuery('#bannerinfo').data('desc');
        var img = jQuery('#bannerinfo').data('img');
        var colour = jQuery('#bannerinfo').data('colour');
        jQuery('#bannertitle').html(title);
        jQuery('#bannerdesc').html(description);
        if (!jQuery('#bannerinfo').attr('video-url')) {
            jQuery('#bannerbg').css('background-image', 'url(' + img + ')');
        }
        if(colour == "box-green") {
            jQuery('#bannerbg').addClass('banner-profile');
            jQuery('#bannerbg .box').removeClass('box-black').addClass(colour);
        }
    };
    if(jQuery('#bannerinfotl').length) {
        var title = jQuery('#bannerinfotl').data('title');
        jQuery('#bannertitle').html(title);
        jQuery('.page-nav li').removeClass('active');
        var title = title.replace(' - ', '---');
        jQuery('.p'+title).addClass('active');
    };

    if(jQuery('.box-articles').length) {
        if(jQuery('.box-articles .box-grey ul').html().trim() == "") {
            jQuery('.box-articles .box-grey').remove();
            //jQuery('.full-medium').removeClass('colr').addClass('pre1');
        }
    }



    // Hack blockquotes
    if(jQuery('.main-content blockquote .sfImageWrapper img').length) {
        jQuery('.main-content blockquote .sfImageWrapper img').closest('blockquote').addClass('bq-w-img');
    }


    // Tag filters
    jQuery('.btn-tag-filters').on('click', function(e){
        e.preventDefault();
        jQuery('.tag-filters').toggleClass('collapsed');
    });
    if(jQuery('body').hasClass('page-how-we-do-it')) {
        var filter = location.pathname;
        if(filter.indexOf('-in-locations') >= 0) {
            var selectedFilterArr = filter.split('/');
            var selectedFilter = selectedFilterArr[selectedFilterArr.length -1];
            var tag = jQuery('.sftaxonItem a[href="'+filter+'"]').html();
            jQuery('.sftaxonItem a[href="'+filter+'"]').addClass('active');
            jQuery( ".masonry" ).before('<h1>Projects in '+tag+'</h1><p><a href="/how-we-do-it" class="btn btn-icon-left"><i class="icon icon-left icon-arrow-left"></i> See all case studies</a></p>');
        } else if(filter.indexOf('-in-sectors') >= 0) {
            var selectedFilterArr = filter.split('/');
            var selectedFilter = selectedFilterArr[selectedFilterArr.length -1];
            var tag = jQuery('.sftaxonItem a[href="'+filter+'"]').html();
            jQuery('.sftaxonItem a[href="'+filter+'"]').addClass('active');
            jQuery( ".masonry" ).before('<h1>Projects in '+tag+' sector</h1><p><a href="/how-we-do-it" class="btn btn-icon-left"><i class="icon icon-left icon-arrow-left"></i> See all case studies</a></p>');
        } else if(filter.indexOf('-in-businessunits') >= 0) {
            var selectedFilterArr = filter.split('/');
            var selectedFilter = selectedFilterArr[selectedFilterArr.length -1];
            var tag = jQuery('.sftaxonItem a[href="'+filter+'"]').html();
            jQuery('.sftaxonItem a[href="'+filter+'"]').addClass('active');
            jQuery( ".masonry" ).before('<h1>Projects in '+tag+' business unit</h1><p><a href="/how-we-do-it" class="btn btn-icon-left"><i class="icon icon-left icon-arrow-left"></i> See all case studies</a></p>');
        }
    }
    if(jQuery('#hidefilters').length) {
        jQuery('.sfsearchBox').closest('.content').hide();
    }


    // Social hub
    jQuery('.social-hub .social-tabs a').on('click', function(e){
        e.preventDefault();
        var tab = jQuery(this).attr('href');
        jQuery('.social-hub .social-tabs li').removeClass('active');
        jQuery(this).parent().addClass('active');
        jQuery('.social-hub .tab').removeClass('active');
        jQuery(tab).addClass('active');
    });



    // Case study gallery
    if(jQuery('.gallery').length) {
        jQuery('.gallery').slick({
            dots: false
        });
    }




    // Work experience form
    if(jQuery('#VisitRequest').length) {
        var request = jQuery('#VisitRequest select').val();
        if (request == "Work Experience") {
            jQuery('#CVUpload').removeClass('field-collapsed');
        } else {
            jQuery('#CVUpload').addClass('field-collapsed');
        }
        jQuery('#VisitRequest select').on('change', function(){
            var request = jQuery(this).val();
            if(request == "Work Experience") {
                jQuery('#CVUpload').removeClass('field-collapsed');
            } else {
                jQuery('#CVUpload').addClass('field-collapsed');
            }
        });
    }

    // Regions map
	jQuery('#regionsmap .map-region').on('click', function () {
		const $this = jQuery(this);
		const region = $this.attr('id');

		jQuery('.location-content').hide();

		jQuery('.map-region').removeClass('is-active');
		$this.addClass('is-active');

		jQuery('#regionsel').val(region);

	    jQuery('#regionslist').removeClass();
	    jQuery('#regionslist').addClass('region-' + region);
	});

    jQuery('#regionsel').on('change', function(){
		const region = jQuery(this).val();

		jQuery('.location-content').hide();

		jQuery('.map-region').removeClass('is-active');
		jQuery('#' + region).addClass('is-active');

		jQuery('#regionslist').removeClass();
		jQuery('#regionslist').addClass('region-' + region);
    });

    // Contact locations
    if(jQuery('.location-content').length) {
        jQuery('.location-content').hide();
        jQuery('.locations-list > ul > li > a').on('click', function(e){
            e.preventDefault();

            jQuery('.location-content').not(jQuery(this).parent().find('.location-content')).each(function(){
                 jQuery(this).parent().find('.location-content').slideUp();
             });
             jQuery(this).parent().find('.location-content').slideToggle();
        });
    };



    //Back to top
    jQuery('.back-to-top a').on('click', function(e) {
        e.preventDefault();
        jQuery('html, body').animate({
            scrollTop: jQuery('html').offset().top
        }, 200);
    });


    // Location Lists

    if(jQuery('.locations-list').length) {


        jQuery('.sfChoiceContent').each(function(i) {

            var trimLoc = jQuery(this).html().trim();
            var arr = trimLoc.split(", ");

            for(i=0; i<arr.length; i++) {
                jQuery(this).closest('.sflistitem').addClass(arr[i].replace(/ /g, '_'));
            }

        });

        
    }


    // Analytics
    jQuery('.foot .social li').on('click', 'a', function(){
        var href = jQuery(this).attr('href');
        var title = jQuery(this).html();
        ga('send', 'social', title, 'Footer Button', href);
    });

    jQuery('.blog-social.social li').on('click', 'a', function(){
        var href = jQuery(this).attr('href');
        var title = jQuery(this).html();
        ga('send', 'social', title, 'Blog Button', href);
    });

    jQuery('.news-social.social li').on('click', 'a', function(){
        var href = jQuery(this).attr('href');
        var title = jQuery(this).html();
        ga('send', 'social', title, 'News Button', href);
    });

    jQuery('.case-social.social li').on('click', 'a', function(){
        var href = jQuery(this).attr('href');
        var title = jQuery(this).html();
        ga('send', 'social', title, 'Case Studies Button', href);
    });


    jQuery('.block-resource').on('click', '.lnk', function(){
        var title = jQuery(this).closest('.block-resource').find('h3').html();
        ga('send', 'event', 'Download PDF', 'Media Center', title);
    });

    jQuery('.btn-sc-form').on('click', function() {
        var href = jQuery(this).attr('href');
        ga('send', 'event', 'Download Web Form', 'Supply Chain Form', href)
    })

    jQuery('.lnk-pdf').on('click', function() {
        var href = jQuery(this).attr('href');
        ga('send', 'event', 'Download Report', 'Financials Report', href)
    })

    // Work Experience form Analytics
    jQuery('#contentPlaceholder_C006_WzdSchoolExperinceForm_StartNavigationTemplateContainerID_StartNextButton').on('click', function() {
        ga('send', 'pageview', '/careers/work-experience-for-schools/school-work-experience-form/your-details');
    })

    jQuery('#contentPlaceholder_C006_WzdSchoolExperinceForm_FinishNavigationTemplateContainerID_FinishButton').on('click', function() {
        ga('send', 'pageview', '/careers/work-experience-for-schools/school-work-experience-form/site-visits-and-events-only');
    })

    jQuery(window).on('load resize', function () {
        matchHeights();
    })

    matchHeights();

    const subnav = document.querySelector('.nav-bar');
    const trigger = document.querySelector('.subnav__button');
    const wrapper = document.querySelector('.subnavModal__wrapper');
    const close = document.querySelector('.subnavModal__button');    
    const modal = document.querySelector('.subnavModal');
    const list = document.querySelector('.subnav__list');  

    if (subnav && modal) {

        const modalList = list.cloneNode(true);

        function openModal() {
            modal.classList.add('is-open');          
            modal.appendChild(modalList);
            modal.setAttribute('aria-hidden', 'false');
        }

        function closeModal() {
            modal.classList.remove('is-open');
            modal.setAttribute('aria-hidden', 'true');
            modal.removeChild(modalList);
        }

        modal.addEventListener('click', function (e) {
            if (e.target == modal) {
                closeModal(e);
            }
        }, false);

        trigger.addEventListener('click', openModal);
        close.addEventListener('click', closeModal);        
    } 

    var navBar = jQuery(".nav-bar");

    jQuery(document).scroll(function(e) {
    
      if (jQuery(document).scrollTop() > 450) {
        navBar.addClass("nav-bar--fixed");
      } else {
        navBar.removeClass("nav-bar--fixed");
      }
  
    });

    jQuery('.accordian').each(function () {
        var accordian = jQuery(this);
        accordian.find('.accordian__header').on('click', function() {
            accordian.toggleClass('accordian--closed');
            accordian.find('.icon-arrow-down').toggle();
            accordian.find('.icon-arrow-right').toggle();
        });
    });

    jQuery('.flyout').each(function () {

        var flyout = jQuery(this);
        jQuery(window).scroll(function(e) {
            if (!flyout.hasClass('flyout--closed') && !flyout.hasClass('flyout--open') && jQuery(window).scrollTop() > 1200) {
                jQuery('.flyout__wrapper').css('top', (1200 + jQuery(window). height())+'px')
               flyout.addClass('flyout--open'); 
            } 
  
        });

        flyout.find('.flyout__close').on('click', function () {
            flyout.removeClass('flyout--open'); 
            flyout.addClass('flyout--closed'); 
        });
    });

    // Cookie Banner

    if (!Cookies.get('AcceptedCookies')) {
        const cookieBanner = document.querySelector('.cookie-banner');
        if (cookieBanner) {
            cookieBanner.classList.add('cookie-banner-visible');

            cookieBanner.querySelector('.accept-cookies').addEventListener('click',
                function () {
                    cookieBanner.classList.remove('cookie-banner-visible')
                    Cookies.set('AcceptedCookies', 'true', { expires: 365 });
                });
        };
    }

    if (typeof grecaptcha !== 'undefined') {
        grecaptcha.ready(function () {
            var formButtons = document.querySelectorAll('input[data-recaptcha-key][data-recaptcha-action]');
            for (let i = 0; i < formButtons.length; i++) {
                let button = formButtons[i];
                let buttonClickEventListener = button.addEventListener('click',
                    function (e) {
                        e.preventDefault();
                        let recaptchaKey = button.dataset.recaptchaKey;
                        let recaptchaAction = button.dataset.recaptchaAction;
                        grecaptcha.execute(recaptchaKey, { action: recaptchaAction }).then(function (token) {
                            document.querySelector('#ReCaptchaToken').value = token;
                            var field = $(button).closest('.field');
                            field.find('.recaptcha-final-submit').click();
                        });
                    });
            }
        });
    }
});

function matchHeights() {

    jQuery('.equal-height__item, .equal-height__img').css('height','auto');

    jQuery('.equal-height__wrapper').each(function() {
        var tallestItem = 0;
        const itemGroup = jQuery(this).find('.equal-height__item');
        itemGroup.each(function() {
            var height = jQuery(this).height();
        if(height > tallestItem) {
            tallestItem = height;
        }
        });

        itemGroup.height(tallestItem);

        var tallestImg = 0;
        const imgGroup = jQuery(this).find('.equal-height__img');
        imgGroup.each(function() {
            var height = jQuery(this).height();
        if(height > tallestImg) {
            tallestImg = height;
        }
        });

        imgGroup.height(tallestImg);        
    });
}
